<template>
    <div id="app">
        <div id="main_container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Index'
}
</script>

<style>
body {
    margin: 0;
}
</style>
