<template>
    <div class="page">
        <div class="nft">
            <img src="/tonyStoriesNFT.png" alt="NFT Tony Stories" />
        </div>
        <div class="mint">
            <img src="/logo-tony.png" alt="Tony Stories mint" style="width: 80%; margin: 0 auto;" />
            <h2>1 NFT Tony Stories +</h2>
            <h2>1 NFT Tony Emotions*</h2>
            <h5>*Abriendo ticket en server de Tony tras mintear</h5>
            <!--<h3>Quedan {{ nftLeft }} NFTs</h3>-->
            <p>&nbsp;</p>
            <h3>Precio 55 MATIC</h3>
            <p>&nbsp;</p>
            <select v-model="quantityNFTs">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
            </select>
            <button v-if="this.walletStatus" @click="getNFT()" >
                Mint
            </button>
            <button v-else @click="connect()">Conectar Wallet</button>
            <a href="https://opensea.io/collection/tony-stories">VER COLECCIÓN EN OPENSEA</a>
        </div>
        <div class="nft">
            <img src="/tonyEmotionsNFT.jpg" alt="NFT Tony Emotions" />
        </div>

        <div class="page2">
            <div class="nft2">
                <img src="/tonyStoriesNFT.png" alt="NFT Tony Stories" />
            </div>
            <div class="nft2">
                <img src="/tonyEmotionsNFT.jpg" alt="NFT Tony Emotions" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Mint",
    data() {
        return {
            walletStatus: false,
            nftLeftOG: "0",
            nftLeftWL: "0",
            nftLeft: "0",
            loadNFT: false,
            quantityNFTs: 1
        };
    },
    mounted() {
        setInterval(async () => {
            this.walletStatus = this.walletManager.walletStatus;

            if(!this.loadNFT) {
                this.loadNFT = true;
                this.loadNFTLeft();
            }
        }, 100);
    },
    methods: {
        async connect() {
            let err = await this.walletManager.connectToMetamask();
            if (err != "") {
                console.log(err);
            }
        },
        async getNFT() {
            let signer = await this.walletManager.web3Global.getSigner();
            let nftSigner = this.walletManager.nft.connect(signer);
            let signerAddress = await signer.getAddress();

            try {
                await nftSigner.mint(signerAddress, this.quantityNFTs, {
                    // value: this.walletManager.ethers.utils.parseUnits('1', 'ether'),
                    value: this.walletManager.ethers.utils.parseUnits((55 * this.quantityNFTs).toString(), 'ether'),
                    gasLimit: 600000
                });
            } catch (e) {
                console.log(e);    
            }
        },
        async loadNFTLeft() {
            await this.walletManager.checkId();
            setTimeout(async () => {
                let number = await this.walletManager.nft.totalSupply();
                if (number > 72) {
                    this.nftLeftOG = 0;
                    number -= 72;
                } else {
                    this.nftLeftOG = 72 - number;
                    number = 0;
                }
                if (number > 107) {
                    this.nftLeftWL = 0;
                    number -= 107;
                } else {
                    this.nftLeftWL = 107 - number;
                    number = 0
                }
                this.nftLeft = 231 - number;
            }, 1000);
        }
    }
};
</script>

<style scoped>
.page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255,255,255);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Fira, Sans, "Droid Sans", "Helvetica Neue", sans-serif;
    min-height: 100vh;
}

.mint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(115,199,205);
    background: linear-gradient(134deg, rgba(115,199,205,1) 0%, rgba(63,69,255,1) 100%);
    color: white;
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.44), 0px 1722px 482px rgba(255, 255, 255, 0.01), 0px 1102px 441px rgba(255, 255, 255, 0.04), 0px 620px 372px rgba(255, 255, 255, 0.15), 0px 276px 276px rgba(255, 255, 255, 0.26), 0px 13px 152px rgba(255, 255, 255, 0.29);
    width: 300px;
    padding: 30px;
}

.nft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.44), 0px 1722px 482px rgba(255, 255, 255, 0.01), 0px 1102px 441px rgba(255, 255, 255, 0.04), 0px 620px 372px rgba(255, 255, 255, 0.15), 0px 276px 276px rgba(255, 255, 255, 0.26), 0px 13px 152px rgba(255, 255, 255, 0.29);
    width: 340px;
    padding: 0px;
}
.nft img {
    width: 100%;
    height: 524px;
}

.page2 {
    display: none;
    justify-content: center;
    align-items: center;
    background: rgb(255,255,255);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, Fira, Sans, "Droid Sans", "Helvetica Neue", sans-serif;
    margin-top: 20px;
}
.nft2 {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.44), 0px 1722px 482px rgba(255, 255, 255, 0.01), 0px 1102px 441px rgba(255, 255, 255, 0.04), 0px 620px 372px rgba(255, 255, 255, 0.15), 0px 276px 276px rgba(255, 255, 255, 0.26), 0px 13px 152px rgba(255, 255, 255, 0.29);
    width: 40%;
    padding: 0px;
}
.nft2 img {
    width: 100%;
}

.mint > h1 {
    text-align: center;
}

.mint > p {
    margin-top: 0;
    margin-bottom: 0;
}

.mint > select {
    border-radius: 10px;
    padding: 10px;
    background-color: transparent;
    color: #E8C445;
    font-size: 24px;
    font-weight: bold;
    border: 3px solid white;
    margin-bottom: 30px;
}


.mint > h2 {
    margin-bottom: 1px;
    margin-top: 1px;
}
.mint > h5 {
    margin-bottom: 1px;
    margin-top: 1px;
}

.mint > h3 {
    margin-bottom: 5px;
    margin-top: 5px;
}

.mint > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: white;
    border: 3px solid white;
    border-radius: 10px;
    font-size: 36px;
    margin-bottom: 20px;
    padding: 5px 40px;
}

.mint > button:hover {
    cursor: pointer;
}

.mint > a, .mint > a:hover, .mint > a:active {
    text-decoration: none;
    color: white;
}

@media (max-width: 600px) {
    .mint {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .nft {
        display: none;
    }
    .page {
        display: block;
    }

    .page2 {
        display: flex;
    }
    .nft2 {
        display: flex;
    }
}
</style>
